import * as React from "react"
import { Flex, FlexList, Space, Text } from "./ui"
import {
  imageListImageVertical,
  imageListImageWrapper,
  imageListText,
} from "./custom-image-list.css"
import { GatsbyImage } from "gatsby-plugin-image"

function ListItem(props) {
  return (
    <Flex
      variant={props.isVertical && "responsive"}
      flexDirection={props.isVertical ? "" : "columnStartEvenSize"}
    >
      <Flex
        variant={!props.isVertical && "numberedListIconHorizontal"}
        className={
          props.isVertical && (imageListImageWrapper, imageListImageVertical)
        }
      >
        {props.image.file.contentType === "image/svg+xml" ? (
          <img
            style={
              props.isVertical
                ? { width: "100px", minWidth: "100px", objectFit: "scale-down" }
                : {
                    height: "150px",
                    minHeight: "150px",
                    objectFit: "scale-down",
                  }
            }
            src={props.image.localFile.svg.dataURI}
            alt={props.image.alt}
            loading="lazy"
          />
        ) : (
          <GatsbyImage
            style={
              props.isVertical
                ? { width: "100px", minWidth: "100px", objectFit: "scale-down" }
                : {
                    height: "150px",
                    minHeight: "150px",
                    objectFit: "scale-down",
                  }
            }
            imgStyle={
              props.isVertical
                ? { width: "100px", minWidth: "100px", objectFit: "scale-down" }
                : {
                    height: "150px",
                    minHeight: "150px",
                    objectFit: "scale-down",
                  }
            }
            image={props.image.localFile.childImageSharp.gatsbyImageData}
            alt={props.image.alt}
          />
        )}
      </Flex>
      {props.isVertical && <Space size={3} />}
      <Text center={props.isVertical ? false : true}>
        <div
          className={imageListText}
          dangerouslySetInnerHTML={{
            __html: props.body,
          }}
        />
      </Text>
    </Flex>
  )
}

export default function CustomImageList(props) {
  return (
    <FlexList
      responsive={!props.isVertical && true}
      variant={props.isVertical ? "columnStart" : "flexStart"}
    >
      {props.items.map((listItem, index) => (
        <ListItem
          key={listItem.id}
          number={index + 1}
          isVertical={props.isVertical}
          {...listItem}
        />
      ))}
    </FlexList>
  )
}
